import '../styles/index.scss';
import $ from "jquery";
import 'selectric';
import 'magnific-popup';
import Inputmask from "inputmask";
import ls from 'local-storage';
import _ from 'lodash';

// CONFIGS
var post_url = 'https://simpleloansite.com/post.php';
var exit_strategy = true;
/*
   HELPERS
 */
/*
 * Basic email validation
 */
function validEmail(email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(email).match(pattern) ? true : false;
}
/*
 * Basic zipcode validation
 */
function isValidZipCode(zip) {
    return /^\d{5}(-\d{4})?$/.test(zip);
}
/*
 * Sets hash value
 */
function setHash(hash) {
    // if(history.pushState) {
    //     history.pushState(null, null, hash);
    // }
    // else {
    //     location.hash = hash;
    // }
}
/*
 * Checks if value in array
 */
function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

var get_params = function(search_string) {
    var parse = function(params, pairs) {
        var pair = pairs[0];
        var parts = pair.split('=');
        var key = decodeURIComponent(parts[0]);
        var value = decodeURIComponent(parts.slice(1).join('='));

        // Handle multiple parameters of the same name
        if (typeof params[key] === "undefined") {
            params[key] = value;
        } else {
            params[key] = [].concat(params[key], value);
        }

        return pairs.length == 1 ? params : parse(params, pairs.slice(1));
    };

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1).split('&'));
};
/*
 USER IP
 */
$.ajax({
    url : '//www.reallygreatrate.com/api/user/ip_address/',
    dataType : 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('#user_ip').val(response['ip_address']);
    }
});

// User age
$('#useragent').val(window.navigator.userAgent);
/*
   MAGNIFIC POPUP
 */
$('.mfp-iframe').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        type: 'iframe',
        mainClass: 'iframe-popup'
    });
});

$('.mfp-inline').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        type: 'inline'
    });
});

/*
    POPUP
*/
function showPopUp(updatHash = true) {
    setTimeout(function () {
        $('.step-form .step.active .radio-button a').first().focus();
    },301);
    $('html').addClass('popup-open');
    $('.popup').fadeIn();
    setTimeout(function () {
        $('html').addClass('popup-relative');
    }, 600);
    $(window).scrollTop(0);
    if(updatHash) setHash('#2');
}
function hidePopUp() {
    $('html').removeClass('popup-relative');
    $('html').removeClass('popup-open');
    $('.popup').fadeOut();
    setHash('');
}
/*
    Generate a TrustedForm Certificate
 */
$('input').on('input', function() {
    this.setAttribute("value", $(this).val());
});

var field = 'xxTrustedFormCertUrl';
var provideReferrer = false;
var tf = document.createElement('script');
tf.type = 'text/javascript'; tf.async = true;
tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random();
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(tf, s);
/*
    LEAD ID
 */
var s = document.createElement('script');
s.id = 'LeadiDscript_campaign';
s.type = 'text/javascript';
s.async = true;
s.src = (document.location.protocol + '//d1tprjo2w7krrh.cloudfront.net/campaign/9cf731ba-6139-dbff-114f-76f196e05eb4.js');
var LeadiDscript = document.getElementById('LeadiDscript');
if($('#LeadiDscript').length > 0) {
    LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
}

/*
    GET Default params
 */
var params = get_params(location.search);
// publisher_id
if(typeof params.publisher_id !== "undefined") {
    $('input[name="publisher_id"]').val(params.publisher_id);
} else {
    $('input[name="publisher_id"]').val(632);
}
// subid
if(typeof params.clickkeyid !== "undefined") {
    $('input[name="subid"]').val(params.clickkeyid);
}
if(typeof params.subid !== "undefined") {
    $('input[name="subid"]').val(params.subid);
}
// rcid
if(typeof params.rcid !== "undefined") {
    $('input[name="rcid"]').val(params.rcid);
} else {
    $('input[name="rcid"]').val('');
}
/*
    HID
 */
$.ajax({
    url : '//www.reallygreatrate.com/api/hdi/?upload_type=' + $('input[name="upload_type"]').val() + '&publisher_id=' + $('input[name="publisher_id"]').val(),
    dataType : 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('input[name="hid"]').val(response['hid']);
    }
});

/*
    ZIP CODE
 */
$('.start-form button').click(function (e) {
    e.preventDefault();
    var $el = $(this).prev('input');
    var $button = $(this);
    var value = $el.val();
    var $form = $el.parent('form');
    $form.find('.error').fadeOut();
    $el.removeClass('input-error');

    if(!$button.is(":disabled")) {
        $button.prop('disabled', true);
        if(value === "" || !isValidZipCode(value)) {
            $el.addClass('input-error');
            $form.find('.error').fadeIn();
        } else {
            getCity(value);
        }
        $button.prop('disabled', false);
    }
});

/*
 * Get city name
 */
var zip_response = null;
function getCity(value) {
    // Check zip code
    $.ajax({
        url: '//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + value,
        dataType: 'json',
        method: 'get',
        data: {'zip': value},
        success: function (response) {
            zip_response = response;
            zip_response['zip'] = value;

            $('input[name="zip"]').val(value);
            $('input[name="city"]').val(response['city']);
            $('input[name="state"]').val(response['state']);

            console.log($('input[name="city"]').val());
            console.log($('input[name="state"]').val());
            console.log($('input[name="zip"]').val());

            $('.street-info').text(response['city']+', '+response['state']+', '+value);
            $('.city-title-popup').text(response['city']);

            ls('zip', value);
            ls('city', response['city']);
            ls('state', response['state']);

            if (response['city']) {
                showPopUp();
            } else {
                $el.addClass('input-error');
                $form.find('.error').fadeIn();
            }
        }
    });
}

$(document).on('change', '.radio-list:visible input', function () {
    $('.radio-list:visible li').removeClass('active');
    $(this).parent('label').parent('li').addClass('active');
});

var step_2 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
        }
    }
};

var step_3 = {
    select : function () {
        // Init custom selects
        $('#property-value').selectric({
            nativeOnMobile: true
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');

            var is_prop_selected = ($('#property-value option:selected').length > 0);
            var prop_value = $('#property-value option:selected').val();
            if(!is_prop_selected || parseInt(prop_value) === -1) {
                $('.step-form .step.active .errors').html('<span class="error">Please select estimated property value</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_3.select();

var step_4 = {
    select : function () {
        // Init custom selects
        $('#loan-amount').selectric({
            nativeOnMobile: true
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');

            var is_loan_selected = ($('#loan-amount option:selected').length > 0);
            var loan_value = $('#loan-amount option:selected').val();
            if(!is_loan_selected || parseInt(loan_value) === -1) {
                $('.step-form .step.active .errors').html('<span class="error">Please select loan value</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_4.select();

var step_5 = {
    select : function () {
        // Init custom selects
        $('#cash-out').selectric({
            nativeOnMobile: true
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');

            var is_cash_selected = ($('#cash-out option:selected').length > 0);
            var cash_value = $('#cash-out option:selected').val();
            if(!is_cash_selected || parseInt(cash_value) === -1) {
                $('.step-form .step.active .errors').html('<span class="error">Please select an option</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_5.select();

var step_6 = {
    select : function () {
        // Init custom selects
        $('#fha-loan').selectric({
            nativeOnMobile: true
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');

            var is_fha_selected = ($('#fha-loan option:selected').length > 0);
            var fha_value = $('#fha-loan option:selected').val();
            if(!is_fha_selected || parseInt(fha_value) === -1) {
                $('.step-form .step.active .errors').html('<span class="error">Please select an option</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_6.select();

var step_7 = {
    select : function () {
        // Init custom selects
        $('#veteran').selectric({
            nativeOnMobile: true
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            var is_veteran_selected = ($('#veteran option:selected').length > 0);
            var veteran_value = $('#veteran option:selected').val();
            if(!is_veteran_selected || parseInt(veteran_value) === -1) {
                $('.step-form .step.active .errors').html('<span class="error">Please select an option</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_7.select();

var step_8 = {
    select : function () {
        // Init custom selects
        $('#veteran').selectric({
            nativeOnMobile: true
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            // Adress
            var street = $('.step-form .step.active input[name="street_address"]').val();
            street = $.trim(street);
            // Zip
            var zip = $('.step-form .step.active input[name="zip"]').val();
            zip = $.trim(zip);
            if (street === "" && zip === "") {
                $('.step-form .step.active .errors').append('<span class="error">Please fill in your Street Address and Zip Code</span>');
                $('.step-form .step.active').addClass('has-errors');
            } else {
                if(street === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Street Address</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
                if(zip === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Zip Code</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
            }
            getCity(zip);
            $button.prop('disabled', false);
        }
    }
};
step_8.select();

var step_9 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            // First name
            var first_name = $('.step-form .step.active input[name="first_name"]').val();
            first_name = $.trim(first_name);
            // Last name
            var last_name = $('.step-form .step.active input[name="last_name"]').val();
            last_name = $.trim(last_name);
            // Email
            var email = $('.step-form .step.active input[name="email_address"]').val();
            email = $.trim(email);
            // Phone
            var phone = $('.step-form .step.active input[name="home_phone"]').val();
            phone = $.trim(phone);
            phone = phone.replace(/[^0-9]/g, '');

            if (first_name === "" && last_name === "" && email === "" && phone === "") {
                $('.step-form .step.active .errors').append('<span class="error">Please fill in your First Name, Last Name, Email and Phone</span>');
                $('.step-form .step.active').addClass('has-errors');
            } else {
                if (first_name === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your First Name</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
                if (last_name === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Last Name</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
                if(phone === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Phone</span>');
                    $('.step-form .step.active').addClass('has-errors');
                } else {
                    if(phone.length < 10) {
                        $('.step-form .step.active .errors').append('<span class="error">Please check Phone Number formatting (9999999999)</span>');
                        $('.step-form .step.active').addClass('has-errors');
                    }
                }
                if(email === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Email Address</span>');
                    $('.step-form .step.active').addClass('has-errors');
                } else {
                    if(!validEmail(email)) {
                        $('.step-form .step.active .errors').append('<span class="error">Please check your Email Address formatting (email@domain.com)</span>');
                        $('.step-form .step.active').addClass('has-errors');
                    }
                }
            }

            $button.prop('disabled', false);
        }
    }
};

function changeStep(step) {
    setHash('#'+step);
    $('.step-form .step').removeClass('active');
    $('.step-form .step[data-step="'+step+'"]').addClass('active');
    $('.form-top .steps .current').text(step);
    $(window).scrollTop(0);
    //$('.step-form .step.active input').first().focus();
    switch(step) {
        case 3:
            //$('input[name="field_2"]').val($('input[name="provider-radio"]:checked').val());
            break;
        case 9:
            // Add input mask
            var selector = document.getElementById("home_phone");
            var im = new Inputmask("(999) 999-9999");
            im.mask(selector);
            break;
    }
}

$(document).on('click', '.step-back', function (e) {
    e.preventDefault();
    var step = $('.step-form .step.active').data('step') - 1;
    changeStep(step);
});

$(document).on('submit', 'form[name="leadform"]', function (e) {
    e.preventDefault();
});

$(document).on('click', '.step-button:visible', function (e) {
    e.preventDefault();
    var step = $(this).data('step');
    if(step === 'submit') {
        step_9.validate();
        if(!$('.step-form .step.active').hasClass('has-errors')) {
            //submit
            var formData = $('#leadform').serialize();
            // Ajax call to post data
            $.ajax({
                url: post_url,
                method: 'GET',
                crossDomain: true,
                data: formData,
                headers: {
                    'Access-Control-Allow-Origin': '*'
                },
                success: function (results) {
                    var pixel = stripslashes(results.pixel.pixel);

                    if (results.system_status == 'success' || results.system_status == 'Flagged') {

                    }

                    // VWO Code to trigger custom event - AJAX form submission to prod endpoint
                    if (mobileTabletCheck()) {
                        var vwo_script = '<script type="text/javascript">window._vis_opt_queue = window._vis_opt_queue || [];window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(204);});</script>';
                    } else {
                        var vwo_script = '<script type="text/javascript">window._vis_opt_queue = window._vis_opt_queue || [];window._vis_opt_queue.push(function() {_vis_opt_register_conversion(1,10);});</script>';
                    }
                    $('body').append(vwo_script);

                    show_confirmation();
                    fetchListings();
                }
            });

        }
    } else {
        switch(step - 1) {
            case 2:
                step_2.validate();
                break;
            case 3:
                step_3.validate();
                break;
            case 4:
                step_4.validate();
                break;
            case 5:
                step_5.validate();
                break;
            case 6:
                step_6.validate();
                break;
            case 7:
                step_7.validate();
                break;
            case 8:
                step_8.validate();
                break;
        }
        if(!$('.step-form .step.active').hasClass('has-errors')) {
            changeStep(step);
        }
    }
});

// Opt out form
$(document).on('click', '#opt-out .opt-out-button', function(e) {
    e.preventDefault();
    var email1 = $.trim($('#emailaddr').val());
    var email2 = $.trim($('#emailaddr2').val());
    var $errors = $('#opt-out .errors');
    var $button = $('#opt-out .opt-out-button');
    $errors.empty();
    if(!$button.is(":disabled")) {
        $button.prop('disabled', true);
        if(email1.length === 0 && email2.length === 0) {
            $errors.append('<span class="error">Must fill Email and Confirm Email fields</span>');
        } else {
            if(email1.length === 0) {
                $errors.append('<span class="error">Must fill Email field</span>');
            } else if(email2.length === 0) {
                $errors.append('<span class="error">Must fill Confirm Email field</span>');
            } else {
                if(!validEmail(email1) && !validEmail(email2)) {
                    $errors.append('<span class="error">Please enter valid Email and Confirm Email</span>');
                } else {
                    if(!validEmail(email1)) {
                        $errors.append('<span class="error">Please enter valid Email</span>');
                    } else if(!validEmail(email2)) {
                        $errors.append('<span class="error">Please enter valid Confirm Email</span>');
                    } else {
                        $.ajax({
                            url : '//www.reallygreatrate.com/api/unsub/?emailaddr=' + email1 + '&table=solar',
                            dataType : 'json',
                            method: 'get',
                            data: {},
                            success: function (response) {
                                if(response['status'] == 'success') {
                                    $('.opt-out-form .form-content').html('Thank you! Your request will be processed in 24-48 hours.');
                                }
                            }
                        });
                    }
                }
            }
        }
        $button.prop('disabled', false);
    }
});

// Tooltip
$(document).on('click', 'a.tooltip', function(e) {
    e.preventDefault();
});

var allowedHashes = ['2', '3', '4', '5', '6', '7', '8', '9'];

$(document).ready(function() {

    var hashValue = location.hash;
    hashValue = hashValue.replace(/^#/, '');

    if(isInArray(hashValue,allowedHashes)) {
        if(ls('zip')) {
            getCity(ls('zip'));
            setTimeout(function () {
                showPopUp(false);
                changeStep(hashValue);
            },200);
        }
        setTimeout(function () {
            $('html').addClass('ready');
        },300);
    } else {
        $('html').addClass('ready');
    }
});

$(document).ready(function() {
    // this line sets the value of an input form in the dom when the input is change
    // TF reads from the dom, so this means later when the function is called
    // any data the user inputs will be availble in the screenshot
    $('input').on('input', function() {
        this.setAttribute("value", $(this).val());
    });
});

var field = 'xxTrustedFormCertUrl';
var provideReferrer = false;
var tf = document.createElement('script');
tf.type = 'text/javascript'; tf.async = true;
tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random();
var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);

///Begin adroll scripts for proptype, cashout and final submit
$(document).ready(function(){
    $('.step[data-step="1"] .step-button').click(function(){
        try{
            __adroll.record_user({"adroll_segments":"2321229f"});
        } catch(err) {}
    });

    $('.step[data-step="5"] .step-button').click(function(){
        try{
            __adroll.record_user({"adroll_segments":"4ce2dba4"});
        } catch(err) {}
    });

    $('.step[data-step="9"] .step-button').click(function(){
        try{
            __adroll.record_user({"adroll_segments":"d1596367"});
        } catch(err) {}
    });
});

function mobileTabletCheck() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
        return true;
    }
    return false;
}

function show_confirmation(manual) {
    manual = (typeof manual === 'undefined') ? '' : manual;
    if (!manual) {
        exit_strategy = false;
    }
    $('#step-form').hide();
    $('.step.active').removeClass('active');
    $('.step-conformation').show();
}

var clicksnet_listing = null;
function clicksnet_callback() {
    (clicksnet_listing.innerHTML = stripAndExecuteScript(
        clicksnet_listing_content.content
    )),
    'undefined' != typeof clicksnet_sub_widget &&
    clicksnet_sub_widget.init_clicksnet_sw(clicksnet_script_src_obj),
        clicksNetAddExpandButtonListeners();
}

function fetchListings() {
    var randomized_num = getRandomNum(100);
    // if random number is less than or equal to 90, show clicks.net confirmation
    if( randomized_num <= 90 ) {
        // CLICKS NET CLICK WALL
        if( exit_strategy == true ) {
            var affcid = "1103401";
            var key = "FEg9_CYDZWw1";
            document.getElementById('confirmation_qs_text').innerText = "Click on a lender below for your free quote!";
        } else {
            var affcid = "1058706";
            var key = "56pg95fEw2k1";
        }
        // Zip
        var zip = $('#zip').val();
        //	 Parameter for Credit Rating
        var clicksnet_credit_rating = 'Good (620 - 719)';
        //	 Parameter for Loan Amount
        var clicksnet_loan_range = '$150,001 - $160,000';
        //	 Parameter for Loan Type and Term
        var clicksnet_loan_type_term = 'Refinance - FHA Rate - 30 Year';
        //	 Subids are used to track conversions and traffic
        var subid1 = $('#publisher_id').val();
        //	 Subids are used to track conversions and traffic
        var subid2 = $('#rcid').val();
        //Optional preview parameter
        var creative_id = (typeof params.preview !== 'undefined') ? params.preview : '';
        //Optional Query Parameters:
        //	 showHeader=[true||false] -> will show or hide header (Default: true)
        //	 showWidget=[true||false] -> will show or hide the subWidget filter bar (Default: true)
        //	 showFooter=[true||false] -> will show or hide footer (Default: true)
        var showHeader = false;
        var showWidget = false;
        var showFooter = false;
        //baseQuery
        //Purpose: base script query
        var baseQuery = clicksNetGetProtocol() + 'cdn.fcmrktplace.com/listing/?affcamid='
            + affcid + '&key=' + key + '&creative_id=' + creative_id
            + '&subid1=' + subid1 + '&subid2=' + subid2;
        //clicksnet_script_src
        //Purpose: script to fetch listings
        //Filter Query Parameters:
        //	 clicksnet_credit_rating: (eg.) Excellent (720 or Above), Good (620 - 719), Fair (580 - 619), Poor (579 or Below)
        //	 clicksnet_loan_range: loan amount (eg.) $70,001 - $80,000
        //	 clicksnet_loan_type_term: Loan Type + Rate Type (eg.) Purchase - FHA Rate - 30 Year
        var clicksnet_script_src = baseQuery + '&zip=' + zip
            + '&clicksnet_credit_rating=' + clicksnet_credit_rating + '&clicksnet_loan_range=' + clicksnet_loan_range
            + '&clicksnet_loan_type_term=' + clicksnet_loan_type_term + '&showHeader=' + showHeader
            + '&showWidget=' + showWidget + '&showFooter=' + showFooter;
        var clicksnet_script_src_obj = {
            baseQuery: baseQuery,
            zip: zip,
            clicksnet_credit_rating: clicksnet_credit_rating,
            clicksnet_loan_range: clicksnet_loan_range,
            clicksnet_loan_type_term: clicksnet_loan_type_term
        };

        var clicksnet_listing_count = clicksnet_listing_count || 0;
        clicksnet_listing_count += 1;
        var clicksnet_listing_id = 'clicksnet_listing_' + clicksnet_listing_count;
        clicksnet_listing = document.getElementById(clicksnet_listing_id);
        document.getElementById('confirmation_clicksnet').innerHTML = '<div id="' + clicksnet_listing_id + '"></div>';
        var clicksnet_script = document.createElement('script');
        (clicksnet_script.type = 'text/javascript'),
            (clicksnet_script.async = !0),
            (clicksnet_script.src = clicksnet_script_src + '&gh=true&ghs=true'),
            clicksnet_script.addEventListener
                ? clicksnet_script.addEventListener('load', clicksnet_callback, !1)
                : clicksnet_script.readyState &&
                (clicksnet_script.onreadystatechange = function() {
                    ('loaded' == clicksnet_script.readyState ||
                        'complete' == clicksnet_script.readyState) &&
                    clicksnet_callback();
                });
        clicksnet_listing.appendChild(clicksnet_script);
    }
}